exports.components = {
  "component---src-ui-pages-404-tsx": () => import("./../../../src/ui/pages/404.tsx" /* webpackChunkName: "component---src-ui-pages-404-tsx" */),
  "component---src-ui-pages-experiments-bazel-tsx": () => import("./../../../src/ui/pages/experiments/bazel.tsx" /* webpackChunkName: "component---src-ui-pages-experiments-bazel-tsx" */),
  "component---src-ui-pages-index-tsx": () => import("./../../../src/ui/pages/index.tsx" /* webpackChunkName: "component---src-ui-pages-index-tsx" */),
  "component---src-ui-templates-author-tsx": () => import("./../../../src/ui/templates/author.tsx" /* webpackChunkName: "component---src-ui-templates-author-tsx" */),
  "component---src-ui-templates-bazel-cli-reference-tsx": () => import("./../../../src/ui/templates/bazel/cli-reference.tsx" /* webpackChunkName: "component---src-ui-templates-bazel-cli-reference-tsx" */),
  "component---src-ui-templates-bazel-release-tsx": () => import("./../../../src/ui/templates/bazel/release.tsx" /* webpackChunkName: "component---src-ui-templates-bazel-release-tsx" */),
  "component---src-ui-templates-generic-page-tsx": () => import("./../../../src/ui/templates/generic-page.tsx" /* webpackChunkName: "component---src-ui-templates-generic-page-tsx" */),
  "component---src-ui-templates-post-tsx": () => import("./../../../src/ui/templates/post.tsx" /* webpackChunkName: "component---src-ui-templates-post-tsx" */),
  "component---src-ui-templates-tags-tsx": () => import("./../../../src/ui/templates/tags.tsx" /* webpackChunkName: "component---src-ui-templates-tags-tsx" */)
}

